* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

nav {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 70px;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}

nav a {
  text-decoration: none;
}

nav h1 {
  font-weight: 400;
  cursor: pointer;
  color: #fff;
}

/* JOIN PAGE */

.join-container {
  width: 100%;
  height: 100vh;
  background: url("./assets/japan-street.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2em;
  background-color: rgba(0, 0, 0, 0.808);
}

.user-form h2 {
  font-weight: 400;
  color: white;
  margin: 1em;
}

.user-form input {
  padding: 0.9em 2em;
  border: none;
  background: #d9d9d9;
  border-radius: 6px;
  margin: 1em;
  width: 100%;
}

.join-btn {
  border: none;
  width: 100%;
  padding: 0.5em;
  margin-top: 3em;
  border-radius: 6px;
  margin-bottom: 1em;
  cursor: pointer;
}

@media (min-width: 1350px) {
  .user-form {
    margin-left: 55em;
  }
}

/* CHAT PAGE */

.chat-container{
  width: 100%;
  height: 100vh;
  background: url("./assets/torii-gate.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-box{
  width: 90%;
  background: rgba(217, 217, 217, 0.8);
  max-width: 650px;
  position: relative;
}

.chat-header{
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 1em 0;
}

.chat-header h2{
  flex: 1;
  text-align: center;
  margin-left: 3.5em;
}

.disconnect-btn{
  margin-right: 1em;
  border: none;
  padding: .75em;
  border-radius: .75em;
  cursor: pointer;
  transition: all .1s ease-in;
}

.disconnect-btn:hover{
  transform: scale(1.05);
  color: white;
  background-color: rgb(207, 55, 55);
}

.chat-body .message-container{
  height: 50vh;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
}

.message{
  margin: 1em;
}


.message-body{
  display: inline-block;
  padding: 1em;
  border-radius: .5em;
  text-align: left;
  max-width: 375px;
}

#them .message-body{
  background-color: #39be39;
}

#you .message-body{
  background-color: aqua;
}

.message-meta p {
  color: rgb(110, 107, 107);
  font-size: .75rem;
}

#you {
  text-align: right;
}

.user-connected{
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.chat-sender{
  display: flex;
}

.emoji-picker{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.emoji-box{
  position: absolute;
  bottom: 55px;
  left: 0;
}

.chat-sender input{
  flex: 1;
  border: none;
  padding: 1em 2em;
}

.send-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background-color: #fff;
  border: none;
  cursor: pointer;
  transition: all .1s ease-in;

}

.send-btn:hover{
  padding: 1em 1.1em;
  background-color: #39be39;
}